(function () {
    Vue.component("mute-confirm-modal", {
        props: ["isModalOpen", "i18n"],
        data: function () {
            return {};
        },
        template: ` 
<div v-if="isModalOpen" class="mute-confirm-modal">
 <div class="mute-confirm-modal--mask" ref="muteConfirmModal">
  <div class="mute-confirm-modal__wrapper">
   <div class="mute-confirm-modal__content" ref="muteConfirmModalContent">
    <!-- Title -->
    <div class="mute-confirm-modal__close">
      <h2 class="mute-confirm-modal__title">{{i18n.txtMuteConfirmModalTitle}}</h2> 
      <button type="button" class="mute-confirm-modal__close-button" aria-label="Close" @click="closeModal"> 
        <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
      </button>
    </div> 
     <!-- End Title --> 
     <!-- Body -->
     <div class="mute-confirm-modal__body">
      <h2 class="mute-confirm-modal__body__responsive-title">{{i18n.txtMuteConfirmModalTitle}}</h2>
      <p v-html="i18n.txtMuteConfirmModalDescription"></p>
     </div>
     <!-- End Body -->
     <!-- Footer -->
     <div class="mute-confirm-modal__footer">
      <div class="mute-confirm-modal__body__buttons">
       <button class="mute-confirm-modal__container__content__cancel" @click="cancelChange">{{i18n.txtMuteConfirmModalCancel}}</button>
       <button class="mute-confirm-modal__container__content__accept" @click="acceptChange">{{i18n.txtMuteConfirmModalConfirm}}</button>
      </div>
     </div>
     <!-- End Footer -->
    </div>
   </div>
  </div>
 </div>
</div>
    `,
        watch: {
            isModalOpen: isModalOpen,
        },
        methods: {
            cancelChange: function () {
                this.$emit("closeMuteConfirmModal", {
                    accepted: false,
                });
            },
            acceptChange: function () {
                this.$emit("closeMuteConfirmModal", {
                    accepted: true,
                });
            },
            closeModal: function () {
                setTimeout(() => {
                    _enableDocumentScroll(true);
                    this.$emit("closeMuteConfirmModal", {
                        accepted: false,
                    });
                }, 200);
            },
            onClickOutside: onClickOutside,
        },
    });

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();
        const el = this.$refs.muteConfirmModalContent;
        if (el && !el.contains(event.target)) {
            _removeOnClickOutsideEvent(this);
            this.$emit("closeMuteConfirmModal", {
                accepted: false,
            });
        }
    }

    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
            } else {
                _enableDocumentScroll(true);
            }
        });
    }

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";
        document.body.style.overflow = value;
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.muteConfirmModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _removeOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.muteConfirmModal.removeEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }
})();
